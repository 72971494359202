
/* eslint-disable */
import {
  chooseEnterprise,
  getUserEnterpriseList,
  LoginCode,
  sendVerificationCode,
  userLogin,
  userNoLogin,
} from "@/api/user";
import cookies from "@/utils/cookies";
import { getStore, setStore } from "@/utils/storage";
import QRCode from "qrcode";
import { getCodeImg, getIP } from "~/api/system";
import { getRsaCode } from "@/utils/auth";

export default {
  layout: "blank",
  head() {
    return {
      title: this.service.websiteTitle || "",
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: this.service.websiteKeyword,
        },
        {
          hid: "description",
          name: "description",
          content: this.service.websiteDesc,
        },
      ],
    };
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (value) {
        if (this.tabKey === "3") {
          if (!/^(0|86|17951)?1[0-9]{10}$/.test(this.map.loginName.trim())) {
            return callback(
              new Error(this.$t("login.pleaseInputSuccessPhone"))
            );
          } else {
            callback();
          }
        } else {
          callback();
        }
      } else {
        if (this.tabKey === "3") {
          return callback(new Error(this.$t("login.pleaseInputSuccessPhone")));
        } else {
          return callback(new Error(this.$t("login.login.pleaseInputNo")));
        }
      }
    };
    return {
      tabKey: "3",
      checkbox: 0,
      service: this.$store.state.webInfo,
      opts: {
        imgCode: "",
        loginType: "password",
        codeUrl: "",
        time: null,
        count: 0,
        showPhoneErrorMessage: "",
        showCodeErrorMessage: "",
      },
      map: {
        password: "",
        verificationCode: "",
        clientId: this.$store.state.clientData.id,
        clientType: 1,
        loginName: "",
        imgVerificationCode: "",
        imgVerificationCodeToken: "",
      },
      rules: {
        loginName: [{ required: true, validator: checkPhone, trigger: "blur" }],
        password: [
          {
            required: true,
            message: this.$t("login.pleaseInputPassword"),
            trigger: "blur",
          },
        ],
        verificationCode: [
          {
            required: true,
            message: this.$t("login.pleaseInputCode"),
            trigger: "blur",
          },
        ],
        imgVerificationCode: [
          {
            required: true,
            message: this.$t("login.pleaseInputCode"),
            trigger: "blur",
          },
        ],
      },
      ctrl: {
        codeErrorLoading: false,
        phoneErrorLoading: false,
      },
      ipInfo: {},
      clientData: this.$store.state.clientData,
      eid: "",
      errorCount: 0,
      imageVerification: this.$store.state.imageVerification,
    };
  },
  watch: {
    errorCount(val) {
      if (val >= 2) {
        this.$store.dispatch("toggleImageVerification", true);
        this.imageVerification = true;
      } else {
        this.imageVerification = false;
      }
    },
    imageVerification(val) {
      if (val) {
        this.getImgCode();
      }
    },
  },
  async asyncData(context) {
    let host = "";
    if (context.req && context.req.headers) {
      host = context.req.headers.host;
    }
    if (process.client) {
      host = document.location.host;
    }
    const webInfo = await context.store.dispatch("GET_WEBINFO", {
      domain: host,
    });
    return {
      service: webInfo,
    };
  },
  mounted() {
    this.$i18n.locale = cookies.getInClient("lang") || "zh";
    getIP().then((res) => {
      this.ipInfo = res;
      setStore("ipInfo", res);
    });
    this.errorCount = parseInt(getStore("loginErrorCount")) || 0;
    if (this.errorCount >= 2) {
      this.$store.dispatch("toggleImageVerification", true);
      this.getImgCode();
    }
    this.getQrcode(this.$t("login.DingTalk"));
    let hostName = window.location.host.split(".")[0];
    if (window.location.hostname === "qipei.polyv.net") {
      hostName = "1506897635905396737";
    }
    console.log("hostName", hostName);
    // if (Number(hostName)) {
    this.$store
      .dispatch("GET_WEBINFO", { domain: window.location.host })
      .then((res) => {
        if (res.eid) {
          this.eid = res.eid;
        }
      });
    // }
  },
  methods: {
    getImgCode() {
      getCodeImg().then((res) => {
        this.map.imgVerificationCodeToken = res.token;
        this.opts.imgCode = res.img;
      });
    },
    handleLoginNo() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          if (!this.checkbox) {
            this.$message.error(this.$t("pleaseAgressLog"));
            return;
          }
          const params = JSON.parse(JSON.stringify(this.map));
          params.clientId = this.clientData.id;
          //params.city = this.ipInfo.city
          //params.province = this.ipInfo.pro
          //params.loginIp = this.ipInfo.ip
          params.os = this.getOsInfo().name + ":" + this.getOsInfo().version;
          params.browser =
            this.getBrowserInfo().name + ":" + this.getBrowserInfo().version;
          if (window.location.href.indexOf("localhost") > -1) {
            params.websiteDomain = "kkkzzzhhh.roncoos.com";
          } else {
            params.websiteDomain = window.location.host;
          }
          params.loginEncryption = await getRsaCode(params.password)
          delete params.password
          userNoLogin(params)
            .then((res) => {
              if (res) {
                setStore("loginErrorCount", 0);
                this.errorCount = 0;
                this.$store.dispatch("toggleImageVerification", false);
                console.log(res);
                if (res) {
                  if (!cookies.getInClient("lang")) {
                    this.$store.commit("SET_LANG", "zh");
                  }
                  this.$store.commit("SET_TOKEN", res.token);
                  this.toOrg(res.eid);
                } else {
                  this.getImgCode();
                  this.$message.error(res.msg);
                }
              }
            })
            .catch(() => {
              this.errorCount++;
              setStore("loginErrorCount", this.errorCount);
              this.getImgCode();
            });
        }
      });
    },
    handleLogin() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          if (!this.checkbox) {
            this.$message.error(this.$t("pleaseAgressLog"));
            return;
          }
          const params = JSON.parse(JSON.stringify(this.map));
          params.clientId = this.clientData.id;
          //params.city = this.ipInfo.city
          //params.province = this.ipInfo.pro
          //params.loginIp = this.ipInfo.ip
          params.os = this.getOsInfo().name + ":" + this.getOsInfo().version;
          params.browser =
            this.getBrowserInfo().name + ":" + this.getBrowserInfo().version;

            params.loginEncryption = await getRsaCode(params.password)
            delete params.password
          if (this.opts.loginType === "password") {
            userLogin(params)
              .then((res) => {
                if (res) {
                  setStore("loginErrorCount", 0);
                  this.errorCount = 0;
                  this.$store.dispatch("toggleImageVerification", false);
                  console.log(res);
                  if (res) {
                    if (!cookies.getInClient("lang")) {
                      this.$store.commit("SET_LANG", "zh");
                    }
                    this.$store.commit("SET_TOKEN", res.token);
                    this.toOrg();
                  } else {
                    this.getImgCode();
                    this.$message.error(res.msg);
                  }
                }
              })
              .catch(() => {
                this.errorCount++;
                setStore("loginErrorCount", this.errorCount);
                this.getImgCode();
              });
          } else {
            LoginCode(params)
              .then((res) => {
                if (res) {
                  setStore("loginErrorCount", 0);
                  this.errorCount = 0;
                  this.$store.dispatch("toggleImageVerification", false);
                  if (!cookies.getInClient("lang")) {
                    this.$store.commit("SET_LANG", "zh");
                  }
                  this.$store.commit("SET_TOKEN", res.token);
                  this.toOrg();
                } else {
                  this.getImgCode();
                  this.$message.error(res.msg);
                }
              })
              .catch(() => {
                this.errorCount++;
                setStore("loginErrorCount", this.errorCount);
                this.getImgCode();
              });
          }
        }
      });
    },
    toOrg(eid) {
      if (this.eid || eid) {
        this.goEnterprise(this.eid || eid);
        return;
      }
      getUserEnterpriseList().then((data) => {
        if(!data) return
        data = data.filter((el) => !el["hasExpire"]);
        if (data.length > 0) {
          if (data.length > 1) {
            setTimeout(() => {
              this.$router.push({ path: "/company" });
            }, 2000);
          } else {
            chooseEnterprise({
              eid: data[0].id,
            }).then((res) => {
              if (res) {
                this.loading = false;
                this.$message.success({
                  message: this.$t("loginSuccess"),
                  showClose: true,
                  duration: 2000,
                  type: "success",
                });
                this.$store.dispatch("SET_EID", data[0].id);
                this.$store.dispatch("GET_USERINFO");
                this.$store
                  .dispatch("GET_AUTHWEBINFO", { domain: window.location.host })
                  .then((res) => {
                    setStore("websiteInfo", res);
                  });
                if (this.$route.query.redirect) {
                  this.$router.push({
                    path: decodeURIComponent(this.$route.query.redirect),
                  });
                } else {
                  this.$router.push({ path: "/home" });
                }
              }
            });
          }
        } else {
          this.$message.error(this.$t("noCompany"));
          this.loading = false;
        }
      });
    },
    goEnterprise(eid) {
      console.log("进入");
      chooseEnterprise({
        eid,
      }).then((res) => {
        if (res) {
          this.loading = false;
          this.$message.success({
            message: this.$t("loginSuccess"),
            showClose: true,
            duration: 2000,
            type: "success",
          });
          this.$store.dispatch("SET_EID", eid);
          this.$store.dispatch("GET_USERINFO");
          this.$router.push({ path: "/home" });
        }
      });
    },
    handleChangeLoginType(type) {
      this.opts.loginType = type;
    },
    handleChangeTab(tab) {
      if (tab.paneName === "1") {
        this.getQrcode(this.$t("login.DingTalk"));
      } else if (tab.paneName === "2") {
        this.getQrcode(this.$t("login.EnterpriseWeChat"));
      } else if (tab.paneName === "4") {
        this.map.loginName = "";
      }
    },
    sendCode() {
      if (this.map.loginName) {
        const TIME_COUNT = 60;
        if (!this.opts.timer) {
          this.opts.count = TIME_COUNT;
          this.opts.timer = setInterval(() => {
            if (this.opts.count > 0 && this.opts.count <= TIME_COUNT) {
              this.opts.count--;
            } else {
              clearInterval(this.opts.timer);
              this.opts.timer = null;
            }
          }, 1000);
        }
        sendVerificationCode({
          phone: this.map.loginName,
          verificationCodeType: 2,
          eid: this.eid || null,
        })
          .then((res) => {
            if (res) {
              this.$message.success(res);
            }
          })
          .catch(() => {
            clearInterval(this.opts.timer);
            this.opts.count = 0;
            this.opts.timer = null;
          });
      } else {
        this.$message.error(this.$t("login.pleaseInputPhone"));
      }
    },
    getBrowserInfo: function () {
      /* eslint-disable */
      const Sys = {};
      const ua = navigator.userAgent.toLowerCase();
      let s;
      (s = ua.match(/rv:([\d.]+)\) like gecko/))
        ? (Sys.ie = s[1])
        : (s = ua.match(/msie ([\d]+)/))
        ? (Sys.ie = s[1])
        : (s = ua.match(/edge\/([\d]+)/))
        ? (Sys.edge = s[1])
        : (s = ua.match(/firefox\/([\d]+)/))
        ? (Sys.firefox = s[1])
        : (s = ua.match(/(?:opera|opr).([\d]+)/))
        ? (Sys.opera = s[1])
        : (s = ua.match(/chrome\/([\d]+)/))
        ? (Sys.chrome = s[1])
        : (s = ua.match(/version\/([\d]+).*safari/))
        ? (Sys.safari = s[1])
        : 0;
      // 根据关系进行判断
      if (Sys.ie) return { name: "IE", version: Sys.ie };
      if (Sys.edge) return { name: "EDGE", version: Sys.edge };
      if (Sys.firefox) return { name: "Firefox", version: Sys.firefox };
      if (Sys.chrome) return { name: "Chrome", version: Sys.chrome };
      if (Sys.opera) return { name: "Opera", version: Sys.opera };
      if (Sys.safari) return { name: "Safari", version: Sys.safari };
      return { name: "Unkonwn", version: "0.0.0" };
    },
    // 获取系统信息
    getOsInfo: function () {
      const userAgent = navigator.userAgent.toLowerCase();
      let name = "Unknown";
      let version = "Unknown";
      if (userAgent.indexOf("win") > -1) {
        name = "Windows";
        if (userAgent.indexOf("windows nt 5.0") > -1) {
          version = "Windows 2000";
        } else if (
          userAgent.indexOf("windows nt 5.1") > -1 ||
          userAgent.indexOf("windows nt 5.2") > -1
        ) {
          version = "Windows XP";
        } else if (userAgent.indexOf("windows nt 6.0") > -1) {
          version = "Windows Vista";
        } else if (
          userAgent.indexOf("windows nt 6.1") > -1 ||
          userAgent.indexOf("windows 7") > -1
        ) {
          version = "Windows 7";
        } else if (
          userAgent.indexOf("windows nt 6.2") > -1 ||
          userAgent.indexOf("windows 8") > -1
        ) {
          version = "Windows 8";
        } else if (userAgent.indexOf("windows nt 6.3") > -1) {
          version = "Windows 8.1";
        } else if (
          userAgent.indexOf("windows nt 6.2") > -1 ||
          userAgent.indexOf("windows nt 10.0") > -1
        ) {
          version = "Windows 10";
        } else {
          version = "Unknown";
        }
      } else if (userAgent.indexOf("iphone") > -1) {
        name = "Iphone";
      } else if (userAgent.indexOf("mac") > -1) {
        name = "Mac";
      } else if (
        userAgent.indexOf("x11") > -1 ||
        userAgent.indexOf("unix") > -1 ||
        userAgent.indexOf("sunname") > -1 ||
        userAgent.indexOf("bsd") > -1
      ) {
        name = "Unix";
      } else if (userAgent.indexOf("linux") > -1) {
        if (userAgent.indexOf("android") > -1) {
          name = "Android";
        } else {
          name = "Linux";
        }
      } else {
        name = "Unknown";
      }
      return {
        name,
        version,
      };
    },
    handleOpenUseLog(key) {
      this.$router.push({
        path: "agreement",
        query: {
          type: key,
        },
      });
    },
    getQrcode(text) {
      QRCode.toDataURL(text || "hello world!", {
        errorCorrectionLevel: "H",
        width: 160,
        height: 160,
      }).then((url) => {
        this.opts.codeUrl = url;
      });
    },
  },
};
